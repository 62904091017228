// extracted by mini-css-extract-plugin
export var primaryColor = "#3978c0";
export var backgroundColor = "#fff";
export var separatorColor = "#9b9b9b";
export var linkHoverColor = "#e5e5e5";
export var sideBorderColor = "#5d90cb";
export var padding = "1.5rem";
export var white = "#fff";
export var namePadding = "1.5rem 0 1rem 0";
export var nameMargin = "0";
export var blockNameSize = "1.2rem";
export var bigFontSize = "1.2rem";
export var normalFontSize = "1.1rem";
export var smallFontSize = ".9rem";